import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Card } from "react-bootstrap";
import Footer from "./components/footer";
import Header from "./components/header";
import ScrollToTop from "react-scroll-to-top";
import { AllRoutes } from "./routes/routes";
import { inject } from "@vercel/analytics";
import { injectSpeedInsights } from "@vercel/speed-insights";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { LoadingOutlined, UpCircleFilled } from "@ant-design/icons";

inject();
// injectSpeedInsights();

function App() {
  return (
    <div className="App">
      <ScrollToTop
        smooth
        component={
          <UpCircleFilled style={{ fontSize: "2rem", color: "black" }} />
        }
      />
      <Router>
        <Header />
        <Card
          className="card-content container"
          // style={{ backgroundColor: "rgb(255, 232, 206)" }}
        >
          <Card.Body>
            <Suspense
              fallback={
                <div className="text-center">
                  <LoadingOutlined />
                </div>
              }
            >
              <AllRoutes />
            </Suspense>
          </Card.Body>
        </Card>
        <Footer />
      </Router>
      <SpeedInsights />
    </div>
  );
}

export default App;
