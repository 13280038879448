export const mainMenu = [
  {
    title: "หน้าแรก",
    path: "/",
  },
  {
    title: "ประวัติ",
    path: "/history",
  },
  {
    title: "สมาชิก",
    subMenu: [
      {
        title: "ทั้งหมด",
        path: "/members",
      },
      {
        title: "อี แซรม - Lee Saerom",
        path: "/members/LeeSaerom",
      },
      {
        title: "ซง ฮายอง - Song Hayoung",
        path: "/members/SongHayoung",
      },
      {
        title: "พัค จีวอน - Park Jiwon",
        path: "/members/ParkJiwon",
      },
      {
        title: "โน จีซอน - Roh Jisun",
        path: "/members/RohJisun",
      },
      {
        title: "อี ซอยอน - Lee Seoyeon",
        path: "/members/LeeSeoyeon",
      },
      {
        title: "อี แชยอง - Lee Chaeyoung",
        path: "/members/LeeChaeyoung",
      },
      {
        title: "อี นากยอง - Lee Nagyung",
        path: "/members/LeeNagyung",
      },
      {
        title: "แพค จีฮอน - Baek Jiheon",
        path: "/members/BaekJiheon",
      },
      {
        divider: true,
      },
      {
        title: "จาง กยูริ - Jang Gyuri (2018-2022)",
        path: "/members/JangGyuri",
      },
    ],
  },
  {
    title: "ผลงาน",
    path: "/discography",
  },
  {
    title: "ตารางงาน",
    path: "/schedule",
  },
  {
    title: "ข่าวสาร",
    path: "/news",
  },
  {
    title: "แปลไทย",
    subMenu: [
      {
        title: "บทสัมภาษณ์/นิตยสาร",
        path: "/translate",
      },
      {
        title: "รายการซับไทย",
        path: "/subthai",
      },
    ],
  },
];
