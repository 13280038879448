import { Nav, Navbar, NavDropdown, NavLink, Container } from "react-bootstrap";
import { mainMenu as menuLinks } from "./navbarLinks";
import { snsLinks } from "../data/snsLists";
import { useLocation } from "react-router-dom";

const NavbarMenu = () => {
  const location = useLocation();

  const MenuItem = ({ title, path, subMenu, id }) => {
    if (subMenu) {
      return (
        <NavDropdown title={title} id={`nav-dropdown-${id}`}>
          {subMenu.map((item, index) => (
            <DropdownItem {...item} key={index} />
          ))}
        </NavDropdown>
      );
    }
    return <NavLink href={path}>{title}</NavLink>;
  };

  const DropdownItem = ({ title, path, divider }) => {
    if (divider) {
      return <NavDropdown.Divider />;
    }

    return (
      <NavDropdown.Item href={path}>
        <small>{title}</small>
      </NavDropdown.Item>
    );
  };

  const SnsItem = ({ link, classLogo, path, viewBox }) => {
    return (
      <Nav.Link href={link} target={"_blank"}>
        <i className={classLogo} style={{fontSize: "1.2rem",color: "gray"}}></i>
      </Nav.Link>
    );
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="sticky-top"
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            alt="fmth-logo"
            src="/logo/fmth_logo-supersonic.png"
            width="40"
            height="40"
            className="d-inline-block me-2"
          />{" "}
          fromis_9 THAILAND
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" activeKey={location.pathname}>
            {menuLinks.map((item, index) => (
              <MenuItem {...item} key={index} />
            ))}
          </Nav>
          <Nav>
            {snsLinks.map((item, index) => (
              <SnsItem {...item} key={index} />
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMenu;
