import { Row, Col } from "react-bootstrap";
import { snsLinks } from "../data/snsLists";

const Footer = () => {
  const twitterSvg = snsLinks.find((e) => {
    return e.title === "twitter";
  });
  const twitterClass = twitterSvg.classLogo.concat(" me-2");
  return (
    <footer className="text-center p-3 bg-dark text-white">
      <div className="container">
        <Row>
          <Col>
            <a
              href="https://twitter.com/fromis_9_TH"
              className="text-decoration-none text-light d-flex justify-content-center align-items-center"
              target={"_blank"}
              rel="noreferrer"
            >
              <i
                className={`${twitterSvg.classLogo} me-2`}
                style={{ fontSize: "1.5rem", color: "white" }}
              ></i>
              fromis_9 THAILAND
            </a>
            <p className="mt-3">
              Copyright &copy; fromis_9 THAILAND official 2024
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
