import { Routes, Route } from "react-router-dom";
import { lazy } from "react";

const Home = lazy(() => import("../pages/home"));
const History = lazy(() => import("../pages/history"));
const Discography = lazy(() => import("../pages/discography"));
const Members = lazy(() => import("../pages/members"));
const Subthai = lazy(() => import("../pages/subthai"));
const News = lazy(() => import("../pages/news"));
const Translate = lazy(() => import("../pages/translate"));
const Schedule = lazy(() => import("../pages/schedule"));

export const AllRoutes = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="history" element={<History />} />
      <Route path="members/*" element={<Members />} />
      <Route path="discography/*" element={<Discography />} />
      <Route path="subthai/*" element={<Subthai />} />
      <Route path="schedule/*" element={<Schedule />} />
      <Route path="news/*" element={<News />} />
      <Route path="translate/*" element={<Translate />} />
      <Route path="*" element={<p>There's nothing here: 404!</p>} />
    </Routes>
  );
};
