import { Carousel, Row, Col } from "react-bootstrap";
import NavbarMenu from "./navbarMenu";
import "../App.css";

const Header = () => {
  const quote = (
    <blockquote className="blockquote text-end">
      <p className="mb-3" style={{ textShadow: "2px 2px 4px black" }}>
        "ขอให้สัญญา! และจะรักษาไว้อย่างดี!
        <br />
        พวกเราจะเป็นไอดอลที่ดีของคุณ!
        <br />
        พวกเราคือ <b>'ฟรอมิสไนน์'</b> ค่ะ"
      </p>
      <footer
        className="blockquote-footer"
        style={{ textShadow: "2px 2px 3px white" }}
      >
        สโลแกนที่เป็นดั่งคำสัญญาของ <cite title="Source Title">ฟรอมิสไนน์</cite>
      </footer>
    </blockquote>
  );
  return (
    <>
      <NavbarMenu />
      <div>
        <Row>
          <Col className="lg-8 m-auto">
            <Carousel fade>
              <Carousel.Item interval={"5000"}>
                <img
                  className="d-block img-fluid"
                  src="/banner/supersonic/banner.png"
                />
              </Carousel.Item>
              <Carousel.Item interval={"5000"}>
                <img
                  className="d-block img-fluid"
                  src="/banner/00_common/20230606-gyuri-comment-fm9ig.jpg"
                />
                <Carousel.Caption className="d-none d-md-block">
                  {quote}
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Header;
